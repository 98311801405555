<template>

    <a-layout style="height:100%;">
        <a-layout-content style="height:100%;">
            <div class="yj-conten" style="min-height:calc(100% - 48px);min-width:max-content">
                <a-layout style="background:#fff;padding:20px;height:100%;">
                    <a-layout-header style="background:#fff;padding:0;height:initial;">
                        <a-form layout="horizontal" style="padding:10px 00px;height:auto;">
                            <a-row>
                                <a-col :span="12">
                                    <a-form-item style="margin-bottom:10px;"
                                                 :label-col="formItemLayout.labelCol"
                                                 :wrapper-col="formItemLayout.wrapperCol">
                                        <a-form layout="inline">
                                            <a-form-item>
                                                <label class="yj-partner-list_lab">角色：</label>
                                                <a-radio-group default-value="-1" @change="radioChange">
                                                    <a-radio-button value="-1">全部</a-radio-button>
                                                    <a-radio-button value="1">客服</a-radio-button>
                                                    <!--<a-radio-button value="2">推广员</a-radio-button>-->
                                                    <a-radio-button value="3">审核员</a-radio-button>
                                                    <a-radio-button value="4">员工</a-radio-button>
                                                    <a-radio-button value="-2">无角色</a-radio-button>
                                                    <a-radio-button value="6">管理员</a-radio-button>
                                                </a-radio-group>
                                            </a-form-item>
                                            <a-form-item>
                                                <label class="yj-partner-list_lab">类型：</label>
                                                <el-select v-model="CreateType"
                                                           placeholder="请选择"
                                                           @change="selectChangeContent"
                                                           style="width:120px;margin-left:10px;">
                                                    <el-option label="全部" value="-1"></el-option>
                                                    <el-option label="个人" value="0"></el-option>
                                                    <el-option label="商户" value="1"></el-option>
                                                </el-select>
                                            </a-form-item>
                                        </a-form>
                                    </a-form-item>
                                </a-col>
                                <a-col :span="12" align='right'>  <a-button type="primary" class="margin-r" @click="check">审核队员</a-button></a-col>
                            </a-row>

                            <a-form-item style="margin-bottom:0px;"
                                         :label-col="formItemLayout.labelCol"
                                         :wrapper-col="formItemLayout.wrapperCol">
                                <a-form layout="inline">
                                    <a-form-item>
                                        <label class="yj-partner-list_lab">团队：</label>
                                        <a-input placeholder="姓名/电话/昵称" style="width:260px;" v-model="PartnerName" />
                                    </a-form-item>
                                    <a-form-item label>
                                        <a-checkbox @change="LevelChange">包含下级</a-checkbox>
                                    </a-form-item>
                                    <a-form-item label>
                                        <a-range-picker :value="Time" @change="handleChange" style="width:260px;" />
                                    </a-form-item>
                                    <a-form-item label>
                                        <a-button type="primary" class="margin-r" @click="QueryData">查询</a-button>

                                        <a-button type="primary" class="margin-r" ghost @click="exportdata">导出</a-button>

                                    </a-form-item>
                                </a-form>
                            </a-form-item>
                        </a-form>
                    </a-layout-header>
                    <a-layout-content style="min-height:calc(100vh - 310px)">
                        <div class="yj-list-box">

                            <a-table :columns="columns" bordered
                                     :data-source="PartnerList"
                                     rowKey="SalePersionID"
                                     :pagination="false"
                                     :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
                                <template v-slot:Infotemplate="text,record,index">
                                    <div class="yj_lists_commodity" @click="PartnerDetail(index,0)" style="cursor:pointer; ">
                                        <div class="yj_lists_commodity_img yj_lists_partner_img">
                                            <div>
                                                <img :src="record.UHeadImg || '/Image/default-avatar.svg'" />
                                            </div>
                                        </div>
                                        <div class="yj_lists_commodity_text">
                                            <div>{{record.Name}} <small class="mylevel">{{record.Levels}}级</small></div>
                                            <div style="font-size:14px">{{record.Phone}}</div>

                                            <div>{{record.mobileLocation}}</div>
                                            <div v-if="record.RoleNameStrs!=''">
                                                <span v-for="role in record.RoleNameStrs.split(',')" :key="role">{{role}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </template>

                                <template v-slot:Leveltemplate="text,record,index">
                                    <div class="">
                                        <div class="yj_lists_commodity_text">

                                            <div v-if="record.UpLevelName!=null">
                                                <a-button type="link"
                                                          style="padding:0;"
                                                          size="small"
                                                          @click="PartnerDetail(index,1)">
                                                    <small>{{record.UpLevelName}}</small>

                                                </a-button>
                                            </div>
                                            <div></div>
                                        </div>
                                    </div>
                                </template>

                                <template v-slot:Nexttemplate="text,record">
                                    <div class="">
                                        <div class="yj_lists_commodity_text">
                                            <div>下级：{{record.NextCountPersonal}}人</div>
                                            <div>下级级数：{{record.NextLevelCount}}</div>
                                            <div></div>
                                        </div>
                                    </div>
                                </template>

                                <template v-slot:JobNumtemplate="text,record">
                                    <div class="">
                                        <div class="yj_lists_commodity_text">
                                            <div style='color:#40a9ff' @click="ShowJobNumInfo(record)">{{record.JobNum ==null ? "未分配":record.JobNum}}</div>
                                            <div></div>
                                        </div>
                                    </div>
                                </template>

                                <template v-slot:Honortemplate="text,record">
                                    <div class="" style="display:flex;justify-content:flex-start;align-items:center;" @click="PromoteStimulatePartnerConfig(record)">
                                        <div class="yj_lists_commodity_img yj_lists_partner_img">
                                            <div>
                                                <img :src="Getsrc(record)" style="width:40px;height:40px;object-fit:cover;" />

                                            </div>
                                        </div>
                                        <div class="yj_lists_commodity_text">

                                            <div style="cursor:pointer;"> {{record.HonorName}}</div>

                                            <div class="star-list">

                                                <div class="stars">

                                                    <div class="stars-f" :title='record.IntegralBar.toFixed(2)' :style="{'width':(record.IntegralBar.toFixed(2))+'%'}"></div>
                                                </div>
                                            </div>
                                            <small>积分：{{record.Score}}</small>
                                        </div>
                                    </div>


                                </template>

                                <template v-slot:Actiontemplate="text,record">
                                    <div class="yj_lists_action" style="width:300px;">

                                        <a-button type="primary" size="small" ghost @click="RolesSetting(record,1)">角色设置</a-button>
                                        <a-divider type="vertical" />
                                        <a-button type="primary" size="small" ghost @click="bindtop()" v-if="record.Levels==1">{{isShowOperation&&"修改"||"绑定"}}</a-button>

                                        <a-button type="primary" size="small" ghost @click="UpdateShow(record)" v-if="record.Levels!=1">转移</a-button>
                                        <a-divider type="vertical" />
                                        <a-button type="primary" size="small" ghost @click="DeleteShow(record)" v-if="record.Levels!=1">注销</a-button>
                                    </div>
                                </template>

                                <template v-slot:UnitType="text,record">
                                    <div style='color:#40a9ff' @click="ShowUnitTypeInfo(record)">
                                        {{GetUnitType(record.UnitType)}}
                                    </div>
                                </template>


                            </a-table>
                            <!-- <div class="yj_lists_score">
                  <div>
                    <small>综合评分</small>
                  </div>
                  <div class="yj_lists_score_bd">{{item.Score}}</div>
                </div> -->
                        </div>
                    </a-layout-content>
                    <a-layout-footer style="background:#fff;padding-bottom:0;padding-top:16px">
                        <div style="padding:10px 0 0" v-if="pagination.total>0">
                            <a-row>
                                <a-col :span="8" class="textal_l">
                                    <a-button type="primary" @click="RolesSetting('',0)">批量设置角色</a-button>
                                </a-col>
                                <a-col :span="16" class="textal_r" v-if="pagination.total>10">
                                    <a-pagination :total="pagination.total"
                                                  show-quick-jumper
                                                  v-model="pagination.current"
                                                  @change="onPaginationChange"></a-pagination>
                                </a-col>
                            </a-row>
                        </div>
                    </a-layout-footer>

                </a-layout>
                <a-modal centered
                         width="300"
                         :visible="visible"
                         :closable="false"
                         :footer="null" @cancel="handleCancel">
                    <img alt="身份绑定" style="width: 100%" :src="BindTopImg" />
                </a-modal>
                <a-modal style="top:20px"
                         :maskClosable="false"
                         @ok="saveRoles"
                         :visible="Rolevisible"
                         @cancel="RolehandleCancel">
                    <div class="roleTag">
                        <div v-for="item in RoleList" :key="item.ID" class="roleSel-spot " style="padding-top:18px;font-size:12px;">
                            <input type="hidden" name="selRoleIds" id="selRoleIds" />
                            <a :class="['light-spot', item.checked? 'slect':'']" @click="addRoles(item.ID)">{{item.Name}}</a>
                        </div>
                    </div>
                </a-modal>
                <a-modal style="top:20px"
                         :maskClosable="false"
                         @ok="Delete"
                         :visible="Deletevisible"
                         @cancel="DeleteCancel">
                    <div class="roleTag">
                        <a-input placeholder="备注" style="width:400px;" v-model="Remarks" />
                    </div>
                </a-modal>

                <a-modal style="top:20px"
                         :maskClosable="false"
                         @ok="EditJobNum"
                         :visible="JobNumvisible"
                         @cancel="JobNumCancel">
                    <div class="roleTag">
                        <span style="margin-top:5px;margin-left:5px">请输入工号:</span>
                        <a-input placeholder="工号" style="width:300px; margin-left:20px" v-model="JobNum" />
                    </div>
                </a-modal>

                <a-modal style="top:20px"
                         :maskClosable="false"
                         @ok="EditDistributionInfo"
                         :visible="Distributionvisible"
                         @cancel="DistributionCancel">
                    <div style="width:500px;height:300px">

                        <div style="width:400px;height:50px">
                            <a-checkbox-group name="a" v-model="result" v-on:change="CheckChange()" direction="horizontal">
                                <a-checkbox v-for="item in PartnerTagsData" 
                                            :key="item.ID" :value="item.ID"  style="float:left">{{item.Name}}</a-checkbox>
                            </a-checkbox-group>
                        </div>

                        <div>
                            <span>合伙人类型</span>
                        </div>
                        <a-radio-group v-model="value" v-on:change="VStoreshow" direction="horizontal">
                            <a-radio :value="0">个体</a-radio>
                            <a-radio :value="1">门店</a-radio>
                        </a-radio-group>


                        <div v-show="Storeshow">
                            <span style="margin-top:15px;margin-left:5px">门店名称:</span>
                            <a-input placeholder="门店名称" style="width:200px; margin-left:20px" v-model="UnitName" />
                            <br />
                            <span style="margin-top:15px;margin-left:5px">门店地址:</span>
                            <a-input placeholder="门店地址" style="width:200px; margin-left:20px" v-model="UnitAddress" />
                            <br />
                            <a-col style="margin: 0 auto" :span="5">
                                <a-upload name="image" :data="ParamData" list-type="picture-card" class="avatar-uploader" action="/FileServer/Upload"
                                          :fileList="fileList" @change="imgChange">
                                    <div v-if="fileList.length < 1">
                                        <a-icon type="plus" />
                                        <div class="ant-upload-text">门店图片</div>
                                    </div>
                                </a-upload>
                            </a-col>


                        </div>
                    </div>
                </a-modal>


                <a-modal style="top:20px"
                         :maskClosable="false"
                         @ok="Update"
                         :visible="Updatevisible"
                         @cancel="UpdateCancel">
                    <div class="roleTag">
                        <a-form layout="horizontal" style="padding:10px 00px;height:auto;">
                            <a-form-item style="margin-bottom:10px;"
                                         :label-col="formItemLayout.labelCol"
                                         :wrapper-col="formItemLayout.wrapperCol">
                                <a-form layout="inline" style="width:500px;">
                                    <a-form-item>
                                        <label class="yj-partner-list_lab">团队：</label>
                                        <a-input placeholder="姓名/电话/昵称" style="width:260px;" v-model="UpdatePartnerName" />
                                    </a-form-item>
                                    <a-form-item label>
                                        <a-button type="primary" class="margin-r" @click="GetUpdateInfo">查询</a-button>
                                    </a-form-item>
                                </a-form>
                            </a-form-item>
                            <a-form-item style="margin-bottom:0px;"
                                         :label-col="formItemLayout.labelCol"
                                         :wrapper-col="formItemLayout.wrapperCol">
                                <a-form layout="inline">
                                    <div class="yj_lists_commodity">
                                        <div class="yj_lists_commodity_img yj_lists_partner_img">
                                            <img :src="UpdateInfo.UHeadImg" />
                                        </div>
                                        <div class="yj_lists_commodity_text">
                                            <div style="cursor:pointer;color:#1890ff" @click="PartnerDetail(index,0)">{{UpdateInfo.Name}}</div>
                                            <div>{{UpdateInfo.WeChat}}</div>
                                            <div>{{UpdateInfo.mobileLocation}}</div>
                                            <div>{{UpdateInfo.RoleNameStrs}}</div>
                                        </div>
                                        <div class="yj_lists_commodity_text">
                                            <div>{{UpdateInfo.Phone}}</div>
                                            <div>{{UpdateInfo.Levels}}</div>
                                            <div>{{UpdateInfo.UpLevelName}}</div>
                                        </div>
                                        <div class="yj_lists_commodity_text">
                                            <div>{{UpdateInfo.NextCountPersonal}}</div>
                                            <div>{{UpdateInfo.NextLevelCount}}</div>
                                        </div>
                                    </div>
                                </a-form>
                            </a-form-item>
                        </a-form>
                    </div>
                </a-modal>
            </div>
        </a-layout-content>
    </a-layout>

</template>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    export default {
        name: "PartnerList_PartnerList",
        data() {
            return {
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 1 }
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 22 }
                    }
                },
                config: {
                    rules: [
                        { type: "object", required: true, message: "Please select time!" }
                    ]
                },
                rangeConfig: {
                    rules: [
                        { type: "array", required: true, message: "Please select time!" }
                    ]
                },
                columns: [
                    {
                        title: "团队成员",
                        dataIndex: 'SalePersionID',
                          width: 160,
                        scopedSlots: { customRender: "Infotemplate" }
                    },
                    // {
                    //     title: "手机号",
                    //     dataIndex: 'Phone',
                    //     width: 180
                    // },
                    {
                        title: "上级",
                        dataIndex: 'Levels',
                        width: 100,
                        scopedSlots: { customRender: "Leveltemplate" }
                    },       
                    {
                        title: "下级团队",
                        dataIndex: 'UpLevelID',
                        width: 180,
                        scopedSlots: { customRender: "Nexttemplate" }
                    },

                    {
                        title: "工号",
                        dataIndex: 'JobNum',
                        width: 130,
                        scopedSlots: { customRender: "JobNumtemplate" }
                    },
                     {
                         title: "合伙人类型",
                         width:120,
                        scopedSlots: { customRender: "UnitType" }
                    },
                    {
                        title: "荣誉等级",
                        dataIndex: "HonorName",
                        width: 180,
                        scopedSlots: { customRender: "Honortemplate" }
                        //customRender: (text, item) => {

                        //    console.log(text)
                        //    if (item.HonorName == null)
                        //        return "青铜"
                        //    else
                        //        return item.HonorName;

                        //    //console.log("item");
                        //    //console.log(text);
                        //    //console.log(item);
                        //    //console.log("item");
                        //}

                    },
                    {
                        title: "加入时间",
                        dataIndex: "InputTime",
                        width: 180

                    },
         
                    {
                        title: "操作",
                        key: "action",
                        scopedSlots: { customRender: "Actiontemplate" }
                    }],
                PartnerList: [],
                pagination: {
                    total: 0,
                    defaultPageSize: 10,
                    current: 1
                },
                flag: true,
                userID: "",
                selRoleIds: "-1", //默认打开查询所有推广员，客服，审核员
                PartnerName: "", //合伙人姓名/电话查询
                LevelCheck: false, //包含下级
                Rolevisible: false,//角色弹框
                RolevisibleType: 1,//角色弹框类型
                Time: [],
                BeginTime: "",
                EndTime: "",
                isShowOperation: 1,
                BindTopImg: null,
                visible: false, //绑定合伙人弹窗
                RoleList: [], //角色集合
                currentUserID: "",//单个修改角色ID
                selectedRowKeys: [],
                Updatevisible: false,
                UpdatePartnerName: "",
                UpdateInfo: {},
                OldUserID: "",
                Remarks: "",
                DeleteUserID: "",
                Deletevisible: false,
                JobNum: "",
                JobNumvisible: false,
                DisTributionID: "",
                CreateType: "-1",
                Distributionvisible: false,
                UnitType: "",
                UnitName: "",
                UnitAddress: "",
                UnitImage: "/img/defaultupload.jpg",
                result: [],
                PartTagList: [],
                PartnerTagsData: [],
                PartnerUnitType: "",
                Storeshow: false,
                Tagsid: 0,
                value: "",
                ParamData: {
					ProcessorName: "Image",
					JsonDataParams: "",
                },
                fileList: [],
                Lat: 0,
                Lng: 0,


            };
        },
        beforeCreate() {
            this.form = this.$form.createForm(this, { name: "time_related_controls" });
        },
        methods: {

            //门店图片
            imgChange: function(info) {
				var self = this;
				if (info.file.status === "error") {
					if (info.file.response && info.file.response.Message) {
						this.$message.error(info.file.response.Message);
					} else {
						this.$message.error(`${info.file.name} 文件上传失败`);
					}
					return;
				}
				if (info.file.status === "done") {
					info.file.url = info.file.response;
				}
				self.fileList = info.fileList;
				self.UnitImage =
					self.fileList.length > 0 ? self.fileList[0].response : "";
			},


            CheckChange: function () {
                if (this.result.length > 3) {
                    this.$message.error("最多可选3个标签");
                    this.result.splice(3, 1);
                }
            },

            //获取合伙人标签
            GetPartnerTags: function () {
                var self = this;
                var op = {
                    url: "/PartnerModule/PartnerList/GetPartnerTags",
                    data: {
                        Tagsid: self.Tagsid,
                    }, OnSuccess: function (data) {
                        console.log("data", data);
                        self.PartnerTagsData = data.data;
                    }
                };
                http.Post(op);
            },


            VStoreshow: function () {
                var self = this;
                console.log("self.value", self.value);
                if (self.value == 1) {
                    self.Storeshow = true;

                } else {
                    self.value = 0;
                    self.UnitType = 0;
                    self.Storeshow = false;
                }
            },

            //合伙人类型
            GetUnitType(rt) {
                switch (rt) {
                    case 0:
                        rt = "个人";
                        break;
                    case 1:
                        rt = "商户";
                        break;
                }
                return rt;
            },
            //编辑合伙人
            EditDistributionInfo() {
                var self = this;
                console.log("UnitType", self.UnitType);
                console.log("self.value", self.value);
                console.log("self.result.toString()", self.result.toString());
                var op = {
                    url: "/PartnerModule/PartnerList/EditDistributionInfo",
                    data: {
                        ID: self.DistributionID,
                        PartnerTagsID: self.value,
                        PartnerTagsList: self.result.toString(),
                        UnintImg: self.UnitImage,
                        UnintName: self.UnitName,
                        UnintAddress: self.UnitAddress,
                        Lat: self.Lat,
                        Lng: self.Lng,

                    }, OnSuccess: function () {
                        self.$message.success("修改成功");
                        self.Distributionvisible = false;
                        self.GetPartnerList();
                    }
                };
                http.Post(op);
            },


            //分配工号
            EditJobNum() {
                var self = this;
                console.log(self.JobNum);
                if (self.JobNum == "" || self.JobNum==null) {
                    self.$message.success("请输入工号");
                } else {
                    var op = {
                        url: "/PartnerModule/PartnerList/EditJobNum",
                        data: {
                            DisTributionID: self.DisTributionID,
                            JobNum: self.JobNum,
                        }, OnSuccess: function () {
                            self.$message.success("分配成功");
                            self.JobNumvisible = false;
                            self.GetPartnerList();
                        }
                    };
                    http.Post(op);
                }
            },
            //显示合伙人编辑
            ShowUnitTypeInfo(e) {
                var self = this;
                self.result = [];
                console.log(e);
                self.UnitName = e.UnitName;
                self.UnitType = e.UnitType;
                self.UnitAddress = e.UnitAddress;
                self.PartTagList = e.PartTagList;
                self.DistributionID = e.DistributionID;
                self.value = e.UnitType;
                //self.result = e.PartTagList;

                if (self.value == 1) {
                    self.Storeshow = true;
                }
                else {
                    self.Storeshow = false;
                }
                if ( self.PartTagList != null) {
                    for (var i = 0; i <  self.PartTagList.split(',').length; i++) {
                        self.result.push(parseInt( self.PartTagList.split(',')[i]));
                    }
                    console.log(self.result.toString());
                }

                var ImgTopic = e.UnitImage;
                if (ImgTopic != "" && ImgTopic != null) {
                    self.fileList = [
                        {
                            name: ImgTopic,
                            response: ImgTopic,
                            status: "done",
                            uid: "0",
                            url: ImgTopic
                        }
                    ];
                    self.UnitImage = ImgTopic;

                } else {
                    self.fileList = [];
                }

                self.Distributionvisible = true;
                self.GetPartnerTags();

            },
            //合伙人编辑取消
            DistributionCancel() {
                var self = this;
                self.Distributionvisible = false;
            },


            //显示编辑工号框
            ShowJobNumInfo(e) {
                var self = this;
                console.log(e);
                self.JobNum = e.JobNum;
                self.DisTributionID = e.DistributionID;
                self.JobNumvisible = true;
            },
            JobNumCancel() {
                this.JobNumvisible = false;
            },


            Getsrc: function (record) {
                return "/image/honor/" + record.HonorLevel + ".png";
            },


            check() {
                var self = this;
                self.$router.push({
                    name: "PartnerCheck_PartnerCheck",
                    query: {}
                });
            },
            onSelectChange(selectedRowKeys) {
                this.selectedRowKeys = selectedRowKeys;
            },
            //批量设置角色弹框
            batch() {
                if (this.selectedRowKeys.length <= 0) {
                    this.$message.error("请选择团队");
                    return;
                }
                this.Rolevisible = true;
            },
            //绑定合伙人取消事件
            handleCancel() {
                this.isBindTop();
                this.visible = false;
            },
            //角色绑定取消事件
            RolehandleCancel() {
                this.Rolevisible = false;
                this.currentUserID = "";
                this.RoleList.forEach(element => {
                    element.checked = false;
                })
            },
            //弹框类型判断
            RolesSetting(e, type) {
                console.log(e)
                console.log(type)
                switch (type) {
                    //批量审核0
                    case 0:
                        this.batch();
                        break;
                    //单个审核1
                    case 1:
                        this.signleSetting(e);
                        break;
                }
                this.RolevisibleType = type;
            },
            //单个修改角色
            signleSetting(e) {
                if (!util.isBlank(e.RoleIdStrs)) {
                    var roles = e.RoleIdStrs.split(',');
                    if (roles.length == this.RoleList.length) {
                        this.RoleList.forEach(element => {
                            element.checked = true;
                        })
                    } else {
                        for (var i = 0; i < this.RoleList.length; i++) {
                            for (var j = 0; j < roles.length; j++) {
                                if (roles[j] == this.RoleList[i].ID) {
                                    this.RoleList[i].checked = true;
                                    continue;
                                }
                            }
                        }
                    }

                } else {
                    this.RoleList.forEach(element => {
                        element.checked = false;
                    })
                }
                this.currentUserID = e.SalePersionID;
                this.Rolevisible = true;


            },
            //向临时数组中添加角色ID
            addRoles(e) {
                for (var i = 0; i < this.RoleList.length; i++) {
                    if (e == this.RoleList[i].ID && this.RoleList[i].checked == false) {
                        this.RoleList[i].checked = true;
                    } else if (e == this.RoleList[i].ID && this.RoleList[i].checked == true) {
                        this.RoleList[i].checked = false;
                    }
                }
            },
            //提交角色修改
            saveRoles() {
                var currentRoles = [];
                for (var i = 0; i < this.RoleList.length; i++) {
                    if (this.RoleList[i].checked) {
                        currentRoles.push(this.RoleList[i].ID);
                    }
                }
                switch (this.RolevisibleType) {
                    case 0:
                        this.saveBatchUsersRole(currentRoles);
                        break;
                    case 1:
                        this.saveSingleUserRole(currentRoles);
                }
            },
            Delete() {
                var self = this;
                if (self.Remarks == "") {
                    self.$message.success("请输入注销原因")
                } else {
                    var op = {
                        url: "/PartnerModule/PartnerCheck/Delete",
                        data: {
                            UserID: self.DeleteUserID,
                            Remarks: self.Remarks,
                        }, OnSuccess: function () {
                            self.$message.success("注销成功")
                            self.Rolevisible = false;
                            self.Deletevisible = false;
                            self.currentUserID = "";

                            self.RoleList.forEach(element => {
                                element.checked = false;
                            })
                            self.GetPartnerList();
                        }
                    };
                    http.Post(op);
                }
            },
            DeleteCancel() {
                this.Deletevisible = false;
            },
            UpdateShow(e) {
                var self = this;
                self.UpdatePartnerName = "";
                self.OldUserID = e.SalePersionID;
                self.Updatevisible = true;
            },
            DeleteShow(e) {
                var self = this;
                self.Remarks = "";
                self.DeleteUserID = e.SalePersionID;
                self.Deletevisible = true;
            },
            Update() {
                var self = this;
                var op = {
                    url: "/PartnerModule/PartnerCheck/Update",
                    data: {
                        OldUserID: self.OldUserID,
                        NewUserID: self.UpdateInfo.SalePersionID
                    }, OnSuccess: function () {
                        self.$message.success("转移成功")
                        self.Rolevisible = false;
                        self.currentUserID = "";

                        self.RoleList.forEach(element => {
                            element.checked = false;
                        })
                        self.GetPartnerList();
                    }
                };
                http.Post(op);
                this.Updatevisible = false;
            },
            UpdateCancel() {
                this.Updatevisible = false;
            },
            GetUpdateInfo() {
                var self = this;
                var op = {
                    url: "/PartnerModule/PartnerList/GetPartnerList",
                    data: {
                        PageIndex: 1,
                        PartnerName: self.UpdatePartnerName,
                        selRoleIds: "-1"
                    },
                    OnSuccess: function (res) {
                        if (res.data.Data.length > 0) {
                            self.UpdateInfo = res.data.Data[0];
                        }
                    }
                };
                http.Post(op);
            },
            //单个用户角色保存
            saveSingleUserRole(currentRoles) {
                var self = this;
                var op = {
                    url: "/PartnerModule/PartnerRole/SetPartnerRole",
                    data: {
                        id: self.currentUserID,
                        roleIds: currentRoles
                    }, OnSuccess: function () {
                        self.$message.success("角色设置成功")
                        self.Rolevisible = false;
                        self.currentUserID = "";

                        self.RoleList.forEach(element => {
                            element.checked = false;
                        })
                        self.GetPartnerList();
                    }
                };
                http.Post(op);
            },
            //批量用户角色保存
            saveBatchUsersRole(currentRoles) {
                var self = this;
                var op = {
                    url: "/PartnerModule/PartnerRole/SetPartnerRoleList",
                    data: {
                        ids: self.selectedRowKeys,
                        roleIds: currentRoles
                    }, OnSuccess: function () {
                        self.$message.success("角色设置成功")
                        self.Rolevisible = false;
                        self.selectedRowKeys = [];
                        self.RoleList.forEach(element => {
                            element.checked = false;
                        })
                        self.GetPartnerList();
                    }
                };
                http.Post(op);
            },
            GetDateTime() {
                // var nDate = new Date();
                var CurrentDate = '';//nDate.getFullYear() + "-" + (nDate.getMonth() + 1) + "-" + nDate.getDate();
                var UpDate = '';// nDate.getFullYear() + "-" + (nDate.getMonth()) + "-" + nDate.getDate();
                this.BeginTime = UpDate;
                this.EndTime = CurrentDate;
                this.Time = [UpDate, CurrentDate];
            },
            //获取角色集合
            GetRole() {
                var self = this;
                var op = {
                    url: "/PartnerModule/PartnerRole/GetPartnerAccountsList",
                    data: {},
                    OnSuccess: function (res) {

                        var tempData = []
                        res.data.forEach(element => {
                            tempData.push({
                                ID: element.ID,
                                Name: element.Name,
                                checked: false
                            })
                        })
                        self.RoleList = tempData;
                    }
                };
                http.Post(op);
            },
            //顶级合伙人身份绑定
            bindtop() {
                var self = this;
                if (!util.isBlank(self.BindTopImg)) {
                    self.visible = true;
                    return;
                } else {
                    self.visible = true;
                    var op =
                    {
                        url: "/PartnerModule/PartnerList/BindTopImg",
                        data: {
                        },
                        OnSuccess: function (res) {
                            self.BindTopImg = res.data
                        }
                    };
                    http.Post(op);
                }
            },
            //包含下级单选事件
            LevelChange(e) {
                this.LevelCheck = e.target.checked;
            },
            //导出
            exportdata: function () {
                var self = this
                var op = {
                    url: "/PartnerModule/PartnerList/GetUserPartnerList",
                    data: {
                        startTime: self.BeginTime,
                        endTime: self.EndTime,
                        PageIndex: self.pagination.current,
                        selRoleIds: self.selRoleIds,
                        PartnerName: self.PartnerName,
                        userID: self.userID,
                        isLevel: self.LevelCheck
                    }, //下载接口参数
                    FileName: "团队列表.xlsx", //下载文件重命名
                    ContentType:
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" //文件Mine类型
                };
                http.DownLoadFile(op);
            },
            //查询
            QueryData() {
                this.pagination.current = 1;
                this.GetPartnerList();
            },
            //时间选择
            handleChange(value) {
                if (value.length == 0) {
                    this.BeginTime = "";
                    this.EndTime = "";
                }
                this.BeginTime = value[0]._d;
                this.EndTime = value[1]._d;
                this.Time = value;
            },
            //角色切换
            radioChange(e) {
                this.pagination.current = 1;
                this.selRoleIds = e.target.value;
                this.GetPartnerList();
            },
            //类型切换
            selectChangeContent (item) {
                var self = this;
                self.pagination.current = 1;
                self.CreateType = item;
                self.GetPartnerList();
            },

            GetPartnerList() {
                var self = this;
                var op = {
                    url: "/PartnerModule/PartnerList/GetPartnerList",
                    data: {
                        startTime: self.BeginTime,
                        endTime: self.EndTime,
                        PageIndex: self.pagination.current,
                        selRoleIds: self.selRoleIds,
                        PartnerName: self.PartnerName,
                        isLevel: self.LevelCheck,
                        CreateType:self.CreateType,
                    },
                    OnSuccess: function (res) {
                        //console.log(res.data.Data);
                        if (res.data.Total > 0) {
                            res.data.Data.forEach(element => {
                                element.InputTime = util.TimeStamptoDateTime(element.InputTime, 'yyyy-MM-dd hh:mm:ss')
                            })
                        }
                        self.PartnerList = res.data.Data
                        self.pagination.total = res.data.Total;
                        self.pagination.defaultPageSize = res.data.RowCount;
                        self.pagination.current = res.data.PageIndex;
                    }
                };
                http.Post(op);
            },
            onPaginationChange(e) {
                this.pagination.current = e;

                this.currentUserID = "";

                this.GetPartnerList();
            },
            //合伙人详情
            PartnerDetail(e, type) {
                console.log(e)
                console.log(type)

                if (type == 0) {

                    this.$router.push({
                        name: "PartnerList_PartnerDetail",
                        query: {
                            ID: this.PartnerList[e].SalePersionID
                        }
                    });
                } else {

                    this.$router.push({
                        name: "PartnerList_PartnerDetail",
                        query: {
                            ID: this.PartnerList[e].UpLevelID
                        }
                    });
                }

            },
            PromoteStimulatePartnerConfig(e) {
                this.$router.push({
                    name: "PromoteStimulatePartnerConfig_List",
                    query: {
                        PartnerID: e.SalePersionID
                    }
                });
            },
            isBindTop() {
                var self = this;
                var op =
                {
                    url: "/PartnerModule/PartnerList/IsBindTop",
                    data: {
                    },
                    OnSuccess: function (res) {
                        self.isShowOperation = res.data;
                    }
                };
                http.Post(op);

            }

        },
        mounted() {
            this.GetDateTime();
            this.isBindTop();
            this.GetRole();
            this.GetPartnerList();


        },
        computed: {
        },
        components: {}
    };
</script>
<style>
    .stars {
        width: 60px;
        overflow: hidden;
        background: url('/Image/honor/star.svg') repeat-x left center /12px 12px;
        height: 18px;
        position: relative;
    }

    .stars-f {
        background: url('/Image/honor/starfull.svg') repeat-x left center /12px 12px;
        height: 18px;
        left: 0;
        content: '';
        bottom: 0;
        position: absolute;
    }

    .roleTag {
        display: flex;
        flex-direction: row;
    }

    .light-spot {
        margin: 0px;
        padding: 10px 15px;
    }

    .light-spot {
        border: 1px solid #e5e5e5;
        padding: 8px 15px;
        color: #333;
        display: inline-block;
        cursor: pointer;
        margin-right: 10px;
        margin-bottom: 10px;
    }

        .slect, .light-spot:hover {
            color: #32a9ff;
            border-color: #32a9ff;
            background: url("/Image/slctip.svg") no-repeat right 20px /20px 20px;
        }
</style>
<style scoped>
    .spin-content {
        border: 1px solid #91d5ff;
        background-color: #e6f7ff;
        padding: 30px;
    }

    .star-list img {
        width: 15px;
        height: 15px;
        object-fit: cover;
    }
    .mylevel{background: #2281f8;color:#fff;font-size: 12px; margin:0;padding:0 5px;border-radius: 2px;}
</style>